import './intro.css'
import React from 'react'
import ImgMonkey from '../../assets/img/monkey.png'
import { BsLinkedin } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { BsDownload } from "react-icons/bs";
import * as A from '../../styles'
import * as I from './introStyles';


const Intro = () => {

    const profileData = {
        name: "Piotr Kiedos",
        jobTitle: "Salesforce Marketing Cloud Developer",
        jobTitle2: "Web Developer",
        imageThumb: ImgMonkey,
            social: {
                linkedin: "https://linkedin.com",
                twitter: "https://twitter.com",
                facebook: "https://facebook.com",
                instagram: "https://instagram.com"
            },
    }
    
    return (
        <A.Content id="home">
            <I.Icontent>
                <I.ILeft>
                    <I.ILeftWrapper>
                        <I.ILeftSocial>

                            <I.ILeftSocialA 
                                href={profileData.social.linkedin}
                            >
                                <BsLinkedin />
                            </I.ILeftSocialA>

                            <I.ILeftSocialA 
                                href={profileData.social.twitter}
                            >
                                <BsTwitter  />
                            </I.ILeftSocialA>

                            <I.ILeftSocialA 
                                href={profileData.social.facebook}
                            >
                                <BsFacebook  />
                            </I.ILeftSocialA>

                            <I.ILeftSocialA 
                                href={profileData.social.instagram}
                            >
                                <BsInstagram  />
                            </I.ILeftSocialA>

                        </I.ILeftSocial>
                        <I.ILeftHero>
                            <I.IIntro>
                                Hello, My name is
                            </I.IIntro>
                            <I.IName>
                                {profileData.name}
                            </I.IName>
                        </I.ILeftHero>
                        <I.ITitle>
                            <I.ITitleWrapper>
                                {profileData.jobTitle}
                            </I.ITitleWrapper>
                        </I.ITitle>
                        <I.IDesc>
                            I program, configure, and develop the Salesforce Marketing Cloud platform according to clients' needs, specializing in creating advanced marketing campaigns and analyzing and optimizing campaign results.
                        </I.IDesc>
                        <I.ICv>
                            <I.IButton>Download CV <BsDownload className="i-icon"/></I.IButton>
                        </I.ICv>
                    </I.ILeftWrapper>
                </I.ILeft>
                <I.IRight>
                    <I.IRightWrapper>
                        <I.IRightImg src={profileData.imageThumb} alt={profileData.name + ' ' + profileData.jobTitle + ' ' + profileData.jobTitle2} />
                    </I.IRightWrapper>
                </I.IRight>
            </I.Icontent>
        </A.Content>
    )
}

export default Intro
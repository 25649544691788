import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './Modal.css'
import { Link, animateScroll as scroll } from "react-scroll";
import { AiOutlineHome } from 'react-icons/ai';
import { BsPCircle } from 'react-icons/bs';
import { MdHomeRepairService } from 'react-icons/md';
import { AiOutlineMail } from 'react-icons/ai';
import * as Mo from './ModalStyles';
import styled from 'styled-components';


const LinkModal = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 50%;
    padding: 20px 0px;
    font-weight: 300;
`;

const IconModal = styled.div`
    padding: 0px 20px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
`;



const ModalMenu = ({show, handleClose}) => {
  return (
      <Modal 
        show={show} 
        onHide={handleClose} 
        animation={false} 
        className="modalShow">
      <Modal.Header closeButton>
        <Modal.Title className="m-title">
          <div className="m-title-a">
            Hello, my friend!
          </div>
          <div className="m-title-b">
            How can I help you?
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Mo.Mmenu>
          <LinkModal 
            to="home" 
            smooth={true} 
            duration={500} 
            offset={-80}
            spy={true}
            onClick={handleClose} 
          >
            <IconModal>
              <AiOutlineHome />
            </IconModal>
              home
          </LinkModal>
          <LinkModal 
            to="about" 
            smooth={true} 
            duration={500} 
            offset={-80}
            spy={true}
            onClick={handleClose} 
          >
            <IconModal>
              <BsPCircle />
            </IconModal>
              about me
          </LinkModal>
          <LinkModal 
            to="services" 
            smooth={true} 
            duration={500} 
            offset={-80}
            spy={true}
            onClick={handleClose} 
          >
            <IconModal>
              <MdHomeRepairService />
            </IconModal>
              services
          </LinkModal>
          <LinkModal 
            to="contact" 
            smooth={true} 
            duration={500} 
            offset={-80}
            spy={true}
            onClick={handleClose} 
          >
            <IconModal>
              <AiOutlineMail />
            </IconModal>
              contact
          </LinkModal>
        </Mo.Mmenu>
      </Modal.Body>
      <Modal.Footer>
        <div className="m-footer">
          <span>© DeGree 2023</span>
        </div>
      </Modal.Footer>
      </Modal>
  )
}

export default ModalMenu;
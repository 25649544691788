import React from 'react';
import './about.css';
import Skills from './skills/Skills';
import Experiences from './experiences/Experiences';
import * as Ab from './AboutStyles';
import * as A from '../../styles';


const About = () => {
    return (
        <A.ContentCenter id="about">
            <A.TitleComponent>
                <A.TitleComponentP>
                    about me
                </A.TitleComponentP>
            </A.TitleComponent>
            <Ab.ARow>
                <Ab.ADesc>
                    <Ab.ALeft>
                        <Skills />
                    </Ab.ALeft>
                    <Ab.ARight>
                        <Experiences />
                    </Ab.ARight>
                </Ab.ADesc>
            </Ab.ARow>
        </A.ContentCenter>
    )
}

export default About;
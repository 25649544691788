import styled from 'styled-components';

export const Body = styled.div`
    width: calc(100% - 100px);
    margin: 0px auto;
    display: flex;
    
    @media (max-width: 768px) {
        width: calc(100% - 30px);
    }
`;
export const Container = styled.div`
    width: 100%;
    margin: 0 auto;
`;

export const Menu = styled.div`
    position: relative;
    z-index: 9999;
    height: 80px;  
`;

export const Section = styled.div`
    background: #272727;
    margin: 0 auto;
    box-sizing: border-box;
`;

export const Content = styled.div`
    width: calc(100% - 0px);
    margin: 0 auto;

    @media (max-width: 768px) {
        width: calc(100% - 0px);
    }
`;

export const ContentCenter = styled.div`
    width: calc(100% - 0px);
    margin: 0 auto;

    @media (max-width: 768px) {
        width: calc(100% - 0px);
    }
`;

export const TitleComponent = styled.div`
    width: 100%;
    margin: 60px 0px 30px;
    display: flex;
    flex-direction: column;

    @media (max-width: 1550px) {
        margin: 60px 0px 30px 0px;
    }
`;
export const TitleComponentP = styled.div`
    font-size: 32px;
    font-weight: 400;
    margin: 0;
    padding: 20px 0px 0px 0px;
    border-top: 1px solid rgb(144, 144, 144);

    &:before {
        content:'';
        width: 15%;
        height: 3px;
        background: #ffc732;
        display: flex;
        top: -22px;
        left: 0;
        position: relative;

        @media (max-width: 768px) {
            width: 50%;
        }
    }
`;
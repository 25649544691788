import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import * as A from './styles'
import Navbar from './components/navbar/Navbar';
import './assets/styles/app.css';
import HomePage from './pages/HomePage/HomePage';
import UserPanel from './pages/User/UserPanel';
import Footer from './components/footer/Footer';


function App() {
  return (
      <BrowserRouter>
      <A.Container>
          <A.Menu>
            <Navbar />
          </A.Menu>
        </A.Container>
        <A.Body>
          <Routes> 
            <Route path='/' element={< HomePage />}></Route>
            <Route path='/user' element={<UserPanel />}></Route>
          </Routes>
        </A.Body>
        <Footer />
      </BrowserRouter>
  );
}

export default App;

import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/img/logo.png';
import Navi from './Navi';
import * as N from './NavbarStyles';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const LogoUrl = styled(Link)`
    display: flex;
    justify-content: center;
`;

const Navbar = () => {

    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsSticky(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <N.Nav className={isSticky ? 'sticky' : ''}> 
            <N.Navbar>
                <N.LogoNav>
                    <LogoUrl to='/'>
                    <img 
                        src={Logo} 
                        alt="DeGree Piotr Kiedos" 
                        className={isSticky ? 'navBar-logo-sticky' : 'navBar-logo'}
                    />
                    </LogoUrl>
                </N.LogoNav>
                <N.MenuNav>
                    <Navi />
                </N.MenuNav>
            </N.Navbar>
        </N.Nav>
    )
}

export default Navbar;
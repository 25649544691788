import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { CiSettings } from 'react-icons/ci'
import { HiDocumentDuplicate } from 'react-icons/hi';
import { BiSupport } from 'react-icons/bi';
import { TbPremiumRights } from 'react-icons/tb';
import { AiOutlineLogout } from 'react-icons/ai'

export const NavigationContainer = styled.div`
  flex: 0 0 200px;
  padding: 0px 20px;
`;

export const NavLink = styled.button`
  display: flex;
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: ${(props) => (props.active ? '1px solid rgb(254, 221, 43)' : 'none')};
  background: none;
  padding: 15px 0px;
  cursor: pointer;
  font-size: ${(props) => (props.active ? '17' : '16')}px;
  color: #fff;
  text-align: left;
  color: ${(props) => (props.active ? 'rgb(254, 221, 43)' : 'fff')};
  font-weight: 300;
  font-family: 'Poppins', sans-serif;

  &:hover {
    color: rgb(254, 221, 43);
  }
`;

export const NavigationUser = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
    position: fixed;
    justify-content: center;
    margin: 0 auto;
    padding: 0 auto;
    top: 0%;
    height: 100vh;

    ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
        padding: 0;
    }
    
    ul li {
        color: #fff;
        list-style: none;
        padding: 20px 20px;
        justify-content: center;
        display: flex;
        align-items: flex-start;
    }
`

export const LinkNavigation = styled(Link)`
    color: #fff;
    text-decoration: none;
    font-weight: 300;
    font-size: 17px;
    display: flex;
`

export const IconCi = styled(CiSettings)`
    justify-content: center;
    display: flex;
    margin-right: 10px;
    height: 24px;
`

export const IconHi = styled(HiDocumentDuplicate)`
    justify-content: center;
    display: flex;
    margin-right: 10px;
    height: 24px;
`

export const IconBi = styled(BiSupport)`
    justify-content: center;
    display: flex;
    margin-right: 10px;
    height: 24px;
`

export const IconTb = styled(TbPremiumRights)`
    justify-content: center;
    display: flex;
    margin-right: 10px;
    height: 24px;
`

export const IconAi = styled(AiOutlineLogout)`
    justify-content: center;
    display: flex;
    margin-right: 10px;
    height: 24px;
`
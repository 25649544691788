import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/styles/app.css';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

const config = {
  auth: {
    clientId: 'eebeb892-0372-4816-a061-0571abe4c35a'
  }
};

const publicClientApplication = new PublicClientApplication(config);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MsalProvider instance={publicClientApplication}>
      <App />
    </MsalProvider>
  </React.StrictMode>
);

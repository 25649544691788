import React, { useEffect, useState } from 'react';

const UserSettings = () => {

    const [users, setUsers] = useState([]);

    const fetchUserData = () => {
        fetch("https://jsonplaceholder.typicode.com/users")
            .then(response => response.json())
            .then(data => setUsers(data));
        };

    useEffect(() => {
        fetchUserData()
    }, []);

    return (
        <div>
            <button onClick={fetchUserData}>Pobierz listę userów</button>
            {users.length > 0 && (
                <ul>

                    {users.map(user => (
                        <li key={user.id}>{user.name}</li>
                    ))}

                </ul>
            )}
        </div>
    )
};
export default UserSettings;